import xbox from "../assets/consoles/xbox_logo.webp";
import ps2 from "../assets/consoles/ps2_logo.webp";
import ps3 from "../assets/consoles/ps3_logo.png";
import ps4 from "../assets/consoles/ps4_logo.png";
import nSwitch from "../assets/consoles/switch_logo.webp";
export interface GameConsoleCatalogInfo {
  id: string;
  name: string;
  description: string;
  logo: string;
}

export const consoles: GameConsoleCatalogInfo[] = [
  { id: "switch", name: "Nintendo Switch", description: "", logo: nSwitch },
  { id: "ps2", name: "PlayStation 2", description: "", logo: ps2 },
  { id: "ps3", name: "PlayStation 3", description: "", logo: ps3 },
  { id: "ps4", name: "PlayStation 4", description: "", logo: ps4 },
  { id: "xbox", name: "Xbox clásico", description: "", logo: xbox },
];

export function getConsoleInfo(
  console: string
): GameConsoleCatalogInfo | null | undefined {
  const index = consoles.findIndex((listConsole) => console == listConsole.id);
  if (index > -1) return consoles.at(index);
  return null;
}

import { collection, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirebase } from "../data/data_provider";
import { GameConsoleCatalogInfo, getConsoleInfo } from "../data/consoles";
import controllerEmoji from "../assets/emojis/controller.svg";
import LazyImage from "../components/lazy_image";
import Loader from "../components/loader";
import { Helmet } from "react-helmet";
import trash from "../assets/icons/remove.png";
import share from "../assets/icons/share.png";
import arrow from "../assets/icons/arrow.png";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ScrollButton from "../components/scroll_button";

interface Game {
  id: string;
  name: string;
  weight: number | null;
  cover: string | null;
}

interface GameCatalogState {
  isLoading: boolean;
  games: Game[];
  allGames: Game[];
  info: GameConsoleCatalogInfo | null | undefined;
  sortedBy: string;
  selectedGames: Game[];
  selectedIds: string[];
  isPanelOpen: boolean;
}

enum SortBy {
  name = "Nombre",
  weight = "Peso",
}

export default function GameCatalog() {
  const { id } = useParams();
  const firebase = useFirebase();
  const [state, setState] = useState<GameCatalogState>({
    isLoading: true,
    games: [],
    allGames: [],
    info: null,
    sortedBy: SortBy.name,
    selectedGames: [],
    selectedIds: [],
    isPanelOpen: false,
  });

  useEffect(() => {
    getGames();
  }, [id]);

  async function getGames() {
    if (firebase == null) return;
    //@ts-ignore
    const info = getConsoleInfo(id);
    if (info == null) {
      setState({ ...state, isLoading: false });
    } else {
      setState({ ...state, info, isLoading: true, games: [], allGames: [] });
    }
    //@ts-ignore
    const catalogos = collection(
      firebase?.database(),
      "catalogos",
      id,
      "games"
    );
    const snapshotGames = await getDocs(catalogos);
    let result: Game[] = [];
    snapshotGames.forEach((game) => {
      const { name, weight, cover } = game.data();
      result.push({ name, weight, id: game.id, cover });
    });
    result = sort(state.sortedBy, result);
    const searchParams = new URLSearchParams(window.location.search);
    let ids: string[] = [];
    let selected: Game[] = [];
    if (searchParams.has("game")) {
      ids = searchParams.getAll("game");
      result.forEach((game) => {
        if (ids.includes(game.id)) {
          selected.push(game);
        }
      });
    }
    setState({
      ...state,
      isLoading: false,
      info,
      games: result,
      allGames: result,
      selectedGames: selected,
      selectedIds: ids,
    });
  }

  function sort(sortBy: string, list: Game[]): Game[] {
    let copy = list;
    switch (sortBy) {
      case SortBy.name:
        copy.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case SortBy.weight:
        copy.sort((a, b) => (a.weight ?? 0) - (b.weight ?? 0));
    }
    return copy;
  }

  function search(value: string) {
    let games = state.allGames.filter((game) =>
      game.name.toLowerCase().includes(value.toLocaleLowerCase())
    );
    setState({ ...state, games });
  }

  function calcTotalWeight(): string {
    let weight = 0;
    state.selectedGames.forEach((game) => (weight += game.weight ?? 0));
    return weight.toFixed(2);
  }

  async function shareLink() {
    const location = window.location;
    let link = `${location.origin}${location.pathname}/?`;

    state.selectedIds.forEach((id, index) => {
      link += `game=${id}`;
      if (index < state.selectedIds.length - 1) {
        link += "&";
      }
    });
    console.info(link);
    const shareData = {
      title: "Juegos seleccionados",
      text: `Estos son los juegos que elejí para mi ${state.info?.name}\n`,
      url: link,
    };
    if (navigator.share) {
      navigator.share(shareData);
    } else {
      await navigator.clipboard.writeText(link);
      toast("Link copiado, ahora puedes enviarlo a tu asesor", {type:'success'});
    }
  }

  return (
    <main className="main-container">
      <Helmet>
        <title>
          Catálogo {state.info?.name ?? "no encontrado"} | Gamepoint
        </title>
        <meta
          property="’og:title’"
          content={`Catálogo ${
            state.info?.name ?? "no encontrado"
          } | Gamepoint`}
        />
      </Helmet>
      <ToastContainer hideProgressBar theme="dark" position="top-right" autoClose={3000} />
      <div className="console-info">
        <div className="data">
          {!state.info || state.isLoading ? (
            <Loader />
          ) : (
            <img src={state.info?.logo} />
          )}
          <h3>
            {state.isLoading
              ? ""
              : state.info?.name ?? "Catalogo no encontrado"}
          </h3>
        </div>
        <input
          list="games-list"
          type="search"
          placeholder="Buscar"
          onChange={(event) => {
            search(event.target.value);
          }}
        />
        <div>
          <label htmlFor="sort">Ordenar por:</label>
          <select
            name="sort"
            id="sort"
            onChange={(event) => {
              let sorted = sort(event.target.value, state.games);
              setState({
                ...state,
                games: sorted,
                sortedBy: event.target.value,
              });
            }}
          >
            <option value={SortBy.name}>{SortBy.name}</option>
            <option value={SortBy.weight}>{SortBy.weight}</option>
          </select>
        </div>
      </div>
      <ul className="cards games">
        {state.isLoading ? (
          <>
          <div className="shimmer card"></div>
          <div className="shimmer card"></div>
          <div className="shimmer card"></div>
          <div className="shimmer card"></div>
          <div className="shimmer card"></div>
          <div className="shimmer card"></div></>
        ) : (
          state.games.map((game) => (
            <li
              className={`card game ${state.selectedIds.includes(game.id)}`}
              title={game.name}
              aria-details={`${game.weight ?? "-"}`}
              key={game.id}
              onClick={(event) => {
                let selected = state.selectedGames;
                let ids = state.selectedIds;
                const index = ids.indexOf(game.id);
                if (index > -1) {
                  selected.splice(index, 1);
                  ids.splice(index, 1);
                } else {
                  selected.push(game);
                  ids.push(game.id);
                }
                let isPanelOpen = state.isPanelOpen;
                if (ids.length == 0) {
                  isPanelOpen = false;
                }
                setState({
                  ...state,
                  selectedGames: selected,
                  selectedIds: ids,
                  isPanelOpen,
                });
              }}
            >
              <LazyImage
                placeholderSrc={controllerEmoji}
                src={game.cover ?? controllerEmoji}
                alt={`Portada ${game.name}`}
              />
              <p>{game.name}</p>
            </li>
          ))
        )}
      </ul>
      <div className={`selected-games ${state.selectedGames.length > 0}`}>
        <ul className={`cards games ${state.isPanelOpen}`}>
          {state.selectedGames.map((game) => (
            <li className="panel-item" key={`${game.id}-selected`}>
              <img src={game.cover ?? controllerEmoji} />{" "}
              <span>{game.name}</span>
            </li>
          ))}
        </ul>
        <div className="main-info">
          <button
            className={`icon-button arrow ${state.isPanelOpen}`}
            onClick={() => {
              setState({ ...state, isPanelOpen: !state.isPanelOpen });
            }}
          >
            <img src={arrow} alt="share" />
          </button>
          <div className="data">
            <p>{`${state.selectedGames.length} juegos elegidos`}</p>
            <p>{`${calcTotalWeight()}Gb aprox.`}</p>
          </div>
          <div className="actions">
            <button title="Limpiar lista" className="icon-button">
              <img
                src={trash}
                alt="reset"
                onClick={() => {
                  setState({
                    ...state,
                    selectedGames: [],
                    selectedIds: [],
                    isPanelOpen: false,
                  });
                }}
              />
            </button>
            <button
              title="Compartir"
              className="icon-button"
              onClick={shareLink}
            >
              <img src={share} alt="share" />
            </button>
          </div>
        </div>
      </div>
      <ScrollButton />
    </main>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import './App.css';
import reportWebVitals from "./reportWebVitals";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import HomeScreen from "./pages/home";
import CatalogScreen from "./pages/catalog";
import { Navigator } from "./data/router";
import GameCatalog from "./pages/games_catalog";
import { DataContext } from "./data/data_provider";
import Firebase from "./data/firebase";
import './assets/background.jpg'
import ErrorPage from "./pages/error";
import ServicesScreen from "./pages/services";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Navigator />}>
      <Route index element={<HomeScreen />} />
      <Route path="catalogo" element={<CatalogScreen />} />
      <Route path="catalogo/:id" element={<GameCatalog />} />
      <Route path="servicios" element={<ServicesScreen />} />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);
root.render(
  <React.StrictMode>
    <DataContext.Provider value={new Firebase()}>
      <RouterProvider router={router} />
    </DataContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

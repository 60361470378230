import Emoji from "../components/emoji";
import { ServiceCard } from "../components/cards";
export default function HomeScreen() {
  return (
    <main className="main-container">
      <h1>
        ¡Bienvenido a Gamepoint Medellín, tu destino definitivo para todo lo
        relacionado con videojuegos! <Emoji name="controller" />
        <Emoji name="sparkles" />
      </h1>

      <p>
        <Emoji name="rocket" />
        <b>Tu experiencia gamer es nuestra prioridad.</b> 🎮🔧 Ofrecemos una
        amplia variedad de consolas de videojuegos, accesorios de alta calidad,
        y videojuegos nuevos y usados, ¡todo en un solo lugar! Además, nuestro
        equipo de técnicos expertos está listo para ofrecerte mantenimiento,
        reparación y modificaciones para que tu consola esté siempre en óptimas
        condiciones. ¡Explora nuestras ofertas y descubre por qué somos la mejor
        opción para tus necesidades de videojuegos!
      </p>
      <h2>¿Qué encontrarás en Gamepoint?</h2>
      <section className="cards">
        <ServiceCard
          title="Videojuegos"
          description="Descubre los lanzamientos más esperados y los clásicos atemporales. ¡Aquí siempre encontrarás algo para cada tipo de gamer!"
          emoji={<Emoji name="alien" />}
        />
        <ServiceCard
          title="Consolas"
          description="Desde las últimas generaciones hasta consolas retro, ofrecemos una variedad de opciones para que puedas elegir tu próxima plataforma de juego."
          emoji={<Emoji name="controller" />}
        />
        <ServiceCard
          title="Taller de Reparación y Modificación"
          description="¿Tu consola necesita un arreglo? ¿Quieres personalizar tu equipo? Nuestros expertos están listos para ofrecerte soluciones rápidas y de calidad."
          emoji={<Emoji name="hammer_wrench" />}
        />
        <ServiceCard
          title="Accesorios y Periféricos"
          description="Encuentra todo lo necesario para mejorar tu setup, desde controles hasta soportes, pasando por auriculares y mucho más."
          emoji={<Emoji name="joystick" />}
        />
      </section>
    </main>
  );
}

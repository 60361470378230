import '../styles/loader.css'
interface LoaderProps{
    reference?:React.LegacyRef<HTMLDivElement> | undefined
}
export default function Loader({reference}:LoaderProps){
    return <div className="sk-fade-in sk-spinner sk-cube-grid" ref={reference}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
}
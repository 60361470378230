import { createContext, useContext } from "react";
import Firebase from "./firebase";

export const DataContext = createContext<Firebase|null>(null);

export const useFirebase = () => {
    const firebaseContext = useContext(DataContext);
    if (firebaseContext === undefined) {
      throw new Error(
        "useFirebase must be used within a FirebaseContext.Provider"
      );
    }
    return firebaseContext;
  };
import { Routes, Route, Outlet } from "react-router-dom";
import NavigationBar from "../components/navigation_bar";
import HomeScreen from "../pages/home";
import CatalogScreen from "../pages/catalog";
import ErrorPage from "../pages/error";
import Footer from "../components/footer";
export function Navigator() {
  return (
    <>
      <NavigationBar />
      <Outlet />
      <Footer />
    </>
  );
}


import { Link } from "react-router-dom";
import { consoles } from "../data/consoles";

export default function CatalogScreen() {
  return (
    <main className="main-container">
      <h1>Nuestro Catálogo de juegos digitales</h1>
      <p>
        Selecciona tus juegos favoritos, genera un link con tu lista y
        compartelo con nosotros para cotizarte.
      </p>
      <ul className="cards">
        {consoles.map((console) => (
          <Link key={console.id} to={`/catalogo/${console.id}`}>
            <li className="card">
              <img
                className="logo"
                src={console.logo}
                alt={`logo ${console.name}`}
              />
              <h3>{console.name}</h3>
            </li>
          </Link>
        ))}
      </ul>
    </main>
  );
}

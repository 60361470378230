import { Helmet } from "react-helmet";
import Emoji from "../components/emoji";
import nSwitch from "../assets/consoles/switch_logo.webp";
import ps3 from "../assets/consoles/ps3_logo.png";
import ps2 from "../assets/consoles/ps2_logo.webp";
import ps4 from "../assets/consoles/ps4_logo.png";
import wiiu from "../assets/consoles/nintendo_Wii_U_logo.png";
import wii from "../assets/consoles/wii_logo.png";
import psvita from "../assets/consoles/psvita_logo.png";
import psp from "../assets/consoles/psp_logo.png";
import gamecube from "../assets/consoles/gamecube_logo.png";
import ds3 from "../assets/consoles/3ds_logo.png";
import xbox360 from "../assets/consoles/xbox_360.png";

export default function ServicesScreen() {
  return (
    <>
      <Helmet>
        <title>Servicios | Gamepoint Medellín</title>
      </Helmet>
      <main className="main-container">
        <h1>
          Nuestros Servicios: Mantenimiento, Reparación y Más para tu Consola
        </h1>
        <p>
          En Gamepoint, entendemos que tu consola de videojuegos es más que un
          simple dispositivo; es la puerta a tus aventuras favoritas. 🎮✨ Por
          eso, te ofrecemos una gama completa de servicios diseñados para
          mantener tu equipo en perfecto estado y mejorar tu experiencia de
          juego. Desde reparaciones rápidas hasta modificaciones personalizadas,
          nuestro equipo de técnicos especializados está aquí para ofrecerte
          soluciones efectivas y de alta calidad. Descubre todos nuestros
          servicios a continuación y encuentra la opción perfecta para tus
          necesidades gamer.
        </p>
        <section>
          <h2>Servicio técnico</h2>
          <ul className="cards">
            <li className="card">
              <Emoji name="wrench" />
              <h3>Reparación</h3>
              <h5>¿Tu consola no enciende o tiene problemas técnicos? 🔧🎮</h5>
              <p>
                Ofrecemos servicios de reparación rápidos y eficientes para
                todos los modelos de consolas. Desde problemas de hardware hasta
                fallos en el software, nuestro equipo de técnicos especializados
                está listo para diagnosticar y solucionar cualquier
                inconveniente.,
              </p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=Hola,%20necesito%20servicio%20t%C3%A9cnico%20para%20mi%20consola%20"
                className="action-button"
              >
                Solicitar reparación
              </a>
            </li>
            <li className="card">
              <Emoji name="screwdriver" />
              <h3>Mantenimiento</h3>
              <h5>¡Mantén tu consola en perfecto estado! 🛠️🎮</h5>
              <p>
                Nuestro servicio de mantenimiento preventivo está diseñado para
                prolongar la vida de tu consola y evitar problemas futuros.
                Ideal para una revisión periódica y para asegurarte de que todo
                funcione a la perfección.
              </p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=Hola,%20quisiera%20saber%20cuanto%20cuesta%20el%20mantenimiento%20para%20mi%20consola"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
          </ul>
        </section>
        <section>
          <h2>Instalación de parches, chips y juegos</h2>
          <ul className="cards">
            <li className="card">
              <img className="logo" src={nSwitch} alt="Nintendo swtich" />
              <h3>Nintendo Switch</h3>
              <h5>Standard, Oled, Lite</h5>
              <p>
                Instalación de chip físico (picofly), memoria microSD de 128Gb y
                10 a elección de nuestro catálogo
              </p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20instalaci%C3%B3n%20del%20chip%20para%20Nintendo%20Switch"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card">
              <img className="logo" src={ps2} alt="PlayStation 2" />
              <h3>PlayStation 2</h3>
              <h5>Fat, Slim</h5>
              <p>
                Programación, memory card, memoria USB de 64Gb y paquete de
                juegos
              </p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20programaci%C3%B3n%20para%20PS2"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card">
              <img className="logo" src={ps3} alt="PlayStation 3" />
              <h3>PlayStation 3</h3>
              <h5>Fat, Slim, Super Slim</h5>
              <p>Programación y paquete de juegos</p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20programaci%C3%B3n%20para%20PS3"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card new">
              <img className="logo" src={ps4} alt="PlayStation 4" />
              <h3>PlayStation 4 </h3>
              <h5>Firmware ver. 9.0 - 11.0</h5>
              <p>
                Dispositivo Raspberry Pi Zero programado y paquete de juegos
              </p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20el%20hack%20para%20PS4"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card">
              <img className="logo" src={wiiu} alt="Nintendo Wii U" />
              <h3>Nintendo Wii U</h3>
              <h5>Standard, Deluxe</h5>
              <p>
                Instalación de sistema Tiramisú y memoria USB de 128Gb con
                paquete de juegos
              </p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20programaci%C3%B3n%20para%20Wii%20U"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card">
              <img className="logo" src={wii} alt="Nintendo Wii" />
              <h3>Nintendo Wii</h3>
              <h5>Standard, Mini</h5>
              <p>Programación, memoria USB de 128Gb y paquete de juegos</p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20programaci%C3%B3n%20para%20Wii"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card">
              <img className="logo" src={ds3} alt="Nintendo 3ds/2ds" />
              <h3>Nintendo 3DS/2DS</h3>
              <h5>Old, New, Xl</h5>
              <p>Programación, memoria microSD de 64Gb y paquete de juegos</p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20programaci%C3%B3n%20para%203DS"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card">
              <img className="logo" src={psvita} alt="PlayStation Vita" />
              <h3>PlayStation Vita</h3>
              <h5>Fat, Slim</h5>
              <p>
                Programación, memoria microSD de 64Gb con adaptador SD2VITA y
                paquete de juegos
              </p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20programaci%C3%B3n%20para%20PSVita"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card">
              <img className="logo" src={psp} alt="PSP" />
              <h3>PSP</h3>
              <h5>1000, 2000, 3000, Go, Street</h5>
              <p>Programación y memoria microSD de 32Gb</p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20programaci%C3%B3n%20para%20PSP"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card">
              <img className="logo" src={xbox360} alt="Xbox 360" />
              <h3>Xbox 360</h3>
              <h5>Fat, Slim, Super Slim</h5>
              <p>Instalación de chip RGH 5.0</p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20instalaci%C3%B3n%20del%20chip%20para%20Xbox%20360"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
            <li className="card">
              <img className="logo" src={gamecube} alt="Nintendo Gamecube" />
              <h3>Nintendo Gamecube</h3>
              <h5>Standard</h5>
              <p>Instalación de chip y memoria SD de 64Gb</p>
              <a
                target="_blank"
                href="https://wa.me/573003550853?text=hola,%20me%20interesa%20la%20programaci%C3%B3n%20para%20Wii"
                className="action-button"
              >
                Solicitar cotización
              </a>
            </li>
          </ul>
        </section>
        <section>
          <h2>Mejoras y modificaciones</h2>
          <ul className="cards">
            <li className="card new">
              <h3>Puerto HDMI</h3>
              <h5>PS2, Xbox, Wii</h5>
              <p>
                No mas conversores externos con imagen borrosa, el puerto HDMI
                interno permite imagen de mayor resolución y compatibilidad con
                pantallas modernas
              </p>
            </li>
            <li className="card">
              <h3>Receptor para controles Bluetooth</h3>
              <h5>PS2</h5>
              <p>
                Disfruta tu consola clásica con controles de ultima generación,
                el receptor Bluetooth es compatible con controles de PS3, PS4,
                PS5, Xbox Series, sin delay ni adaptadores externos, incluye
                interruptor de apagado si deseas usar controles con cable.
              </p>
            </li>
            <li className="card">
              <h3>Batería recargable en control</h3>
              <h5>Xbox 360, Xbox One/Series, Wii/Wii U</h5>
              <p>
                Ya no necesitarás comprar mas pilas, disfruta largas jornadas de
                juego con una bateria de 1000mAh y si se descarga puedes
                contectarlo y seguir jugando mientras se carga usando cualquier
                cable USB-C
              </p>
            </li>
          </ul>
        </section>
      </main>
    </>
  );
}

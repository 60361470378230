import { ReactNode } from "react";

interface serviceCardProps{
    emoji: ReactNode;
    title: string,
    description: string
}
export function ServiceCard({emoji, title, description}:serviceCardProps){
    return <div className="card">
        {emoji}
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
}
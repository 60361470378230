import { CSSProperties, useEffect, useRef, useState } from "react";
import Loader from "./loader";

interface LazyImageProps {
  placeholderSrc: string;
  placeholderClassName?: string | undefined;
  placeholderStyle?: CSSProperties | undefined;
  src: string;
  alt: string;
  className?: string | undefined;
  style?: CSSProperties | undefined;
}

const LazyImage = ({
  placeholderSrc,
  placeholderClassName,
  placeholderStyle,
  src,
  alt,
  className,
  style,
}: LazyImageProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState("");
  const placeholderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setView(src);
        //@ts-ignore
        observer.unobserve(placeholderRef.current);
      }
    });

    if (placeholderRef && placeholderRef.current) {
      observer.observe(placeholderRef.current);
    }
  }, [src]);

  return (
    <>
      {isLoading && (
        <Loader reference={placeholderRef} />
      )}
      <img
        src={view} 
        className={className}
        style={isLoading ? { display: "none" } : style}
        alt={alt}
        onLoad={() => setIsLoading(false)}
      />
    </>
  );
};
export default LazyImage;

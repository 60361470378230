import { Link } from "react-router-dom";
import "../styles/header.css";
import logo from "../assets/logo_transparent.webp";
import menu from "../assets/icons/menu.png";
import { useState } from "react";
import { consoles } from "../data/consoles";
export default function NavigationBar() {
  const [visible, setVisible] = useState(false);
  return (
    <header>
      <nav>
        <Link to="/">
          <img className="logo" src={logo} alt="gamepoint logo" />
        </Link>
        <button
          className="hamburguer"
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <img src={menu} alt="menu" />
        </button>
        <ul className={`nav-items ${visible}`}>
          <li className="nav-item">
            <Link
              onClick={() => {
                setVisible(false);
              }}
              to="/"
            >
              Inicio
            </Link>
          </li>
          <li className="nav-item"><Link to="/servicios">Servicios</Link></li>
          <li className="nav-item">
            <span>Catálogos</span>
            <ul className="submenu">
              {consoles.map((console) => (
                <li className="nav-item" key={console.id}>
                  <Link
                    onClick={() => {
                      setVisible(false);
                    }}
                    to={`/catalogo/${console.id}`}
                  >
                    {console.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          {/* <li className="nav-item">
            <Link to="/contact">Contacto</Link>
          </li> */}
        </ul>
      </nav>
    </header>
  );
}

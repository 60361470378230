import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
const firebaseConfig = {
  apiKey: "AIzaSyCNjYfub44qDOqPrkQFVbKBUJKyzf0gtwU",
  authDomain: "gamepoint-store.firebaseapp.com",
  projectId: "gamepoint-store",
  storageBucket: "gamepoint-store.appspot.com",
  messagingSenderId: "255288240908",
  appId: "1:255288240908:web:e9855ca7b5f750bba27729",
  measurementId: "G-HH4HJ94Z8L",
};

class Firebase {
  constructor() {
    if (!getApps().length) {
      initializeApp(firebaseConfig);
    }
  }

  database() {
    return getFirestore(getApp());
  }
}

export default Firebase;
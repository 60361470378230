import "../styles/footer.css";
import instagram from "../assets/social/instagram.png";
import tiktok from "../assets/social/tik-tok.png";
import whatsapp from "../assets/social/whatsapp.png"
export default function Footer() {
  return (
    <footer>
      <address>
        <label htmlFor="email">Correo electronico</label>
        <a id="email" href="mailto:gamepointservicio@gmail.com">
          gamepointservicio@gmail.com
        </a>
        <label htmlFor="phone-number">Whatsapp</label>
        <a id="phone-number" href="tel:+573003550853">
          300 355 0853
        </a>
        Cl 48d # 65a - 57, CC Carrusel de exito, Local 301
      </address>
      <div className="social" aria-label="Redes sociales">
      <a href="https://wa.me/573003550853">
          <img src={whatsapp} alt="whatsapp logo" />
        </a>
        <a href="https://instagram.com/gamepointmedellin">
          <img src={instagram} alt="instagram logo" />
        </a>
        <a href="https://tiktok.com/@gamepointmedellin">
          <img src={tiktok} alt="tik-tok logo" />
        </a>
      </div>
    </footer>
  );
}

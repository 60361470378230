import hammer_wrench from "../assets/emojis/hammer_wrench.svg";
import controller from "../assets/emojis/controller.svg";
import rocket from "../assets/emojis/rocket.svg";
import sparkles from "../assets/emojis/sparkles.svg";
import joystick from "../assets/emojis/joystick.svg";
import alien from "../assets/emojis/alien_pixel.svg";
import growing_star from "../assets/emojis/growing_star.svg";
import wrench from "../assets/emojis/wrench.svg";
import screwdriver from "../assets/emojis/screwdriver.svg";

interface emojiProps {
  name:
    | "hammer_wrench"
    | "controller"
    | "joystick"
    | "rocket"
    | "sparkles"
    | "growing_star"
    | "alien"
    | "wrench"
    | "screwdriver";
}
export default function Emoji({ name }: emojiProps) {
  function getEmoji(): string {
    switch (name) {
      case "controller":
        return controller;
      case "hammer_wrench":
        return hammer_wrench;
      case "rocket":
        return rocket;
      case "sparkles":
        return sparkles;
      case "joystick":
        return joystick;
      case "growing_star":
        return growing_star;
      case "alien":
        return alien;
      case 'wrench':
        return wrench;
        case 'screwdriver':
          return screwdriver;
      default:
        return controller;
    }
  }
  return <img src={getEmoji()} alt={`${name} emoji`} className="emoji" />;
}
